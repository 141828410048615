import React from 'react'
import TeaserSection from './TeaserSection'
import TeaserHeader from './TeaserHeader'

const ContactSection = ({ className }) => (
  <TeaserSection className={className}>
    <TeaserHeader title="Ins Gespräch kommen">
      <div className="contact-information" id="contact-information">
        <div className="title-desc mb-0">
          <div>
            <p>Wir können Ihnen weiterhelfen? Sie haben eine Frage oder benötigen mehr Informationen? Dann findet Ihr
              Anliegen über eine der nachfolgend aufgeführten Möglichkeiten den Weg zu uns.</p>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <h4 className="display-decor">Kontakt</h4>
              <p>
                  <span>Telefon: <a className="phone-link" aria-label={'Telefonnummer BUSCHMAIS GbR'}
                                    href="tel:+493513209230">+49 351 3209230</a></span>
                <br/><span>Fax: <a className="phone-link" aria-label={'Faxnummer BUSCHMAIS GbR'}
                                   href="tel:+4935132092329">+49 351 32092329</a></span>
                <br/><span>E-Mail: <a className="phone-link" href="mailto:info@buschmais.com"
                                      aria-label={'E-Mail-Kontakt BUSCHMAIS'}>info@buschmais.com</a> </span>
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <h4 className="display-decor">Postanschrift</h4>
              <p>
                <span>BUSCHMAIS GbR</span>
                <br/><span>Leipziger Str. 93</span>
                <br/><span>01127 Dresden</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </TeaserHeader>
  </TeaserSection>
)

export default ContactSection
