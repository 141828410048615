/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import * as PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

const Seo = ({
               description,
               lang,
               meta,
               title,
               jobTitle,
               jobDescription,
               jobDatePosted,
               jobIdentifier,
             }) => {
  const { siteMeta, ogImg } = useStaticQuery(
    graphql`{
        siteMeta: site {
            siteMetadata {
                title
                description
                author
                siteUrl
            }
        }
        ogImg: file(relativePath: {eq: "logo_open_graph.png"}) {
            childImageSharp {
                gatsbyImageData(layout: FIXED)
            }
        }
    }
    `,
  )

  const metaDescription = description || siteMeta.siteMetadata.description
  const openGraphLogo = getSrc(ogImg)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={(title && title.length > 0 ? title + ' - ' : '') + 'BUSCHMAIS GbR'}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: 'og:image',
          content: siteMeta.siteMetadata.siteUrl + openGraphLogo,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'org:site_name',
          content: 'BUSCHMAIS GbR',
        },
        {
          property: 'org:locale',
          content: 'de_DE',
        },
        {
          name: 'twitter:site',
          content: '@buschmais',
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMeta.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {jobTitle && jobDescription && jobDatePosted && jobIdentifier &&
        <script type="application/ld+json">{
          JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'JobPosting',
            'title': jobTitle,
            'description': '<p>' + jobDescription + '</p>',
            'identifier': {
              '@type': 'PropertyValue',
              'name': 'BUSCHMAIS',
              'value': jobIdentifier,
            },
            'datePosted': jobDatePosted,
            'employmentType': ['FULL_TIME', 'CONTRACTOR'],
            'hiringOrganization': {
              '@type': 'Organization',
              'name': 'BUSCHMAIS',
              'sameAs': 'https://www.buschmais.de',
              'logo': 'https://www.buschmais.de/buschmais-icon.png',
            },
            'jobLocation': {
              '@type': 'Place',
              'address': {
                '@type': 'PostalAddress',
                'streetAddress': 'Leipziger Str. 93',
                'addressLocality': 'Dresden',
                'addressRegion': 'Sachsen',
                'postalCode': '01127',
                'addressCountry': 'DE',
              },
            },
            'applicantLocationRequirements': {
              '@type': 'Country',
              'name': 'Deutschland',
            },
            'jobLocationType': 'TELECOMMUTE',
          })
        }</script>
      }
    </Helmet>
  )
}
Seo.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}
Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  jobDatePosted: PropTypes.string,
  jobDescription: PropTypes.string,
  jobIdentifier: PropTypes.string,
  jobTitle: PropTypes.string,
  jobValidThrough: PropTypes.string,
}
export default Seo
