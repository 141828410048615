import React from 'react'
import Seo from '../../components/Seo'
import BigTitle from '../../components/BigTitle'
import TeaserSection from '../../components/TeaserSection'
import TeaserHeader from '../../components/TeaserHeader'
import ContactSection from '../../components/ContactSection'
import List from '../../components/List'
import ListItem from '../../components/ListItem'
import DefaultSection from '../../components/DefaultSection'
import Article from '../../components/Article'
import NavigationLink from '../../components/NavigationLink'
import TeaserBody from '../../components/TeaserBody'

const Page = () => {

  return (
    <React.Fragment>
      <Seo title="Innovation"
           description="Unsere Innovationskraft treibt uns voran. Wir optimieren, automatisieren und revolutionieren Prozesse: unsere Forschungs- und Open-Source-Projekte im Überblick."
      />

      <BigTitle title="Innovation" className="mask-black" textInverted={true}
                imageRelativePath={'Titelbild_Innovation.jpg'}
                menuInverted={true} logoInverted={true}>
        Unsere Forschungs- und Open-Source-Projekte sind unser Beitrag zur Optimierung und Digitalisierung von
        Geschäftsprozessen sowie zur Förderung des IT-Nachwuchses in Sachsen.
      </BigTitle>
      <DefaultSection>
        <Article>
          <h1>Forschung</h1>
          <p>Gemeinsam mit regionalen Hochschulen und Forschungseinrichtungen entstehen immer wieder Lösungen, mit denen
            wir die Welt von morgen verändern können.</p>
          <p>Mit der Technischen Universität Dresden und der Berufsakadmie Dresden pflegen wir seit vielen Jahren
            eine enge Partnerschaft. Unser Ziel ist es, den IT-Nachwuchs im Standort Sachsen zu fördern und
            zuhalten. <NavigationLink to="/unternehmen/kooperationen/"
                                      ariaLabel="Kooperationen mit Hochschulen" asTextLink>Lesen Sie Details zu unseren
              Kooperationen mit Hochschulen.</NavigationLink></p>
          <p>
            Die Zusammenarbeit mit dem Institut für Wirtschaftsinformatik der Universität Leipzig z. B. umfasst den
            Bereich
            der Software-Analyse und Visualisierung. Ziel ist es hier, das Verständnis von Strukturen komplexer, über
            Jahre
            gewachsener Anwendungen maßgeblich zu erleichtern sowie Schwachstellen aufzudecken und zu bewerten.
            Die folgende Projekte entstanden aus dieser Zusammenarbeit:</p>

        </Article>
      </DefaultSection>

      <DefaultSection className="bg-level-1">
        <List>
          <ListItem linkTo="https://github.com/softvis-research/jqa-dashboard"
                    linkText="GitHub">
            <h3>jQA Dashboard</h3>
            <p>Im Rahmen einer Masterarbeit wurde ein erweiterbares jQAssistant Dashboard entwickelt, welches
              Metriken, Issues oder Hotspots visualisiert.</p>
          </ListItem>
          <ListItem
            linkTo="http://home.uni-leipzig.de/svis/file/repository/vissoft2018_nier_paper.pdf"
            linkText="PDF">
            <h3>NIER-Paper</h3>
            <p>In Rahmen eines gemeinsamen NIER-Papers (New Ideas And Emerging Results) wurde ein offener und
              erweiterbarer Ansatz für Software-Analysen entwickelt.</p>
          </ListItem>
          <ListItem
            linkTo="https://summerofcode.withgoogle.com/archive/2019/projects/4893531502542848/" linkText="Projekt">
            <h3>Google Summer of Code</h3>
            <p>Während der Google Summer of Code 2019 (GSoC) wurde ein studentisches Projekt zur Erfassung und Analyse
              von Issues in JIRA-Systemen realisiert.</p>
          </ListItem>
        </List>
      </DefaultSection>

      <TeaserSection>
        <TeaserHeader title={'Open Source'} hasBody>
          <p>
            Unsere Innovationskraft treibt uns voran. Wir optimieren, automatisieren und revolutionieren Prozesse.
            Hier finden Sie unsere Open-Source-Projekte, die uns am Herzen liegen.
          </p>
        </TeaserHeader>
        <TeaserBody>
          <List>
            <ListItem linkTo="https://github.com/jqassistant" linkText="GitHub">
              <h3>jQAssistant</h3>
              <p>Werkzeug zur Analyse, Absicherung und Dokumentation komplexer Softwaresysteme </p>
            </ListItem>
            <ListItem linkTo="https://github.com/buschmais/sar-framework" linkText="GitHub">
              <h3>SAR Framework</h3>
              <p>Werkzeug zur Identifikation von Komponenten im Source Code und zur Definition von Zielstrukturen</p>
            </ListItem>
            <ListItem linkTo="https://github.com/buschmais/extended-objects" linkText="GitHub">
              <h3>eXtended Objects</h3>
              <p>Leichtgewichtiges und flexibles Object-Graph-Mapping-Framework für Java, welches im Rahmen von
                jQAssistant weiterentwickelt wird</p>
            </ListItem>
          </List>
        </TeaserBody>
      </TeaserSection>
      <TeaserSection className="bg-level-2">
        <TeaserHeader title="Werde Teil des Teams" linkTo={'/karriere/'} linkTitle={'Karriere'}>
          <p className="mb-5">Dir fehlen neue Herausforderungen? Oder bist Du auf der Suche nach einem spannenden
            Forschungsthema im Rahmen einer Abschlussarbeit? Melde Dich gerne bei uns!</p>
        </TeaserHeader>
      </TeaserSection>
      <ContactSection/>

    </React.Fragment>
  )
}

export default Page
