import * as PropTypes from 'prop-types'
import React from 'react'
import NavigationLink from './NavigationLink'

const ListItem = ({ title, children, linkTo, linkText, linkAriaLabel, linkAsActionButton }) => (

  <div className="col-sm-6 col-lg-4 p-0 pr-md-3 mb-5 mb-lg-3">
    <div className="card border-0 bg-transparent h-100">
      <div className="card-body p-0">
        {title &&
        <h2 className="card-title pb-3">{title}</h2>
        }
        {children}
      </div>
      <div className="card-footer bg-transparent border-0 mb-3 p-0 pt-1">
        {linkTo &&
        <NavigationLink to={linkTo} ariaLabel={linkAriaLabel}
                        asActionButton={linkAsActionButton}>{linkText || 'Details'}</NavigationLink>
        }
      </div>
    </div>
  </div>
)

export default ListItem

ListItem.propTypes = {
  children: PropTypes.node,
  linkText: PropTypes.string,
  linkTo: PropTypes.string,
  title: PropTypes.string,
  linkAsActionButton: PropTypes.bool,
}
