import * as PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import animateScrollTo from 'animated-scroll-to'
import classNames from 'classnames'

const NavigationLink = ({ to, ariaLabel, asTextLink, asActionButton, className, buttonClassName, children }) => {

  const scrollOptions = {
    speed: 10,
    minDuration: 100,
    maxDuration: 1500,
    verticalOffset: 80,
    offset: 0,
    cancelOnUserAction: true,
    passive: true,
    horizontal: false,
  }

  const downloadFileExtensions = /(.*?)\.(ppt|pptx|doc|docx|xls|xlsx|zip|gz|tgz|pdf)$/i
  const btnClasses = buttonClassName ? buttonClassName : 'btn-black'

  if (to && to.startsWith('#')) {
    return (
      <button type="button" className={classNames({
        'btn btn-line': !asTextLink && !asActionButton,
        'btn': asActionButton, [btnClasses]: asActionButton,
      }, className)} onClick={(e) => {
        e.preventDefault()
        animateScrollTo(document.querySelector(to), scrollOptions)
      }}>
        <span className="text text-left">{children}</span>
      </button>
    )
  } else if (to && (to.startsWith('http') || downloadFileExtensions.test(to))) {
    return (
      <a href={to} className={classNames({
        'btn btn-line': !asTextLink && !asActionButton,
        'btn': asActionButton, [btnClasses]: asActionButton,
      }, className)}
         rel="noopener noreferrer" target="_blank" aria-label={ariaLabel}>
        <span className="text text-left">{children}</span>
      </a>
    )
  } else {
    return (
      <Link to={to} className={classNames({
        'btn btn-line': !asTextLink && !asActionButton,
        'btn': asActionButton, [btnClasses]: asActionButton,
      }, className)}>
        <span className="text text-left">{children}</span>
      </Link>
    )
  }
}

export default NavigationLink

NavigationLink.propTypes = {
  to: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  asTextLink: PropTypes.bool,
  asActionButton: PropTypes.bool,
  className: PropTypes.string,
}
